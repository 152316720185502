export interface DeckCard {
  value: string | number
  suit: string
}

export class Deck {
  public cards: DeckCard[] = []

  constructor() {
    this.reset()
    this.shuffle()
  }

  public reset() {
    this.cards = []

    const suits = ["hearts", "spades", "clubs", "diamonds"]
    const values = ["A", 2, 3, 4, 5, 6, 7, 8, 9, 10, "J", "Q", "K"]

    for (let suit in suits) {
      for (let value in values) {
        this.cards.push({
          value: values[value],
          suit: suits[suit],
        })
      }
    }
  }

  public shuffle() {
    const { cards } = this
    let m = cards.length,
      i: number

    while (m) {
      i = Math.floor(Math.random() * m--)
      ;[cards[m], cards[i]] = [cards[i], cards[m]]
    }

    return this
  }

  public deal() {
    return this.cards.pop()
  }
}
