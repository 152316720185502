import React, { FC, useEffect, useState } from "react"
import Layout from "../Layout"
import Seo from "../Seo"
import { CardGame, Suits } from "@monorepo/games/CardGame"
import { BaseGameLocales } from "@monorepo/games/BaseGame"
import { Button, Typography } from "@material-ui/core"
import { Deck, DeckCard } from "../../utils/Deck"
import { cardImages } from "../../utils/cardImages"

export interface CardGameLayoutProps {
  game: CardGame
  language: BaseGameLocales
  onGameEnd: () => void
}
export const CardGameLayout: FC<CardGameLayoutProps> = ({
  game,
  language,
  onGameEnd,
}) => {
  const [deck, setDeck] = useState<Deck>(new Deck())
  const [card, setCard] = useState<DeckCard | null>(null)

  const drawCard = () => {
    const newCard = deck.deal()
    if (newCard) {
      setCard(newCard)
      setDeck(deck)
      return
    }

    onGameEnd()
  }

  useEffect(() => {
    deck.shuffle()
    setDeck(deck)
    drawCard()
  }, [])

  if (!card) {
    return <></>
  }

  const image =
    // @ts-ignore
    cardImages[`${card.value}_${card.suit}`]

  return (
    <Layout
      primaryColor={game.colors.primary}
      primaryColorDark={game.colors.primaryDark}
      title={game.name[language]}
    >
      <Seo
        name={game.name[language]}
        description={game.description[language]}
        language={language}
      />

      {card && (
        <>
          <img src={image} onClick={drawCard} />

          <Typography variant="h3" component="h1" gutterBottom>
            {game.cards[card.value as Suits].title[language]}
          </Typography>
          <Typography gutterBottom={true}>
            {game.cards[card.value as Suits].question[language]}
          </Typography>
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={drawCard}
      >
        {game.drawNext[language]}
      </Button>
    </Layout>
  )
}
