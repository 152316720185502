import React, { FC, ReactNode } from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import ListSubheader from "@material-ui/core/ListSubheader"
import Typography from "@material-ui/core/Typography"

interface Game {
  url: string
  icon: string
  name: string
  description: string
}

export interface OtherGamesListProps {
  title: string
  LinkComponent: (url: string, children: React.ReactNode) => ReactNode
  games: Game[]
}
export const OtherGamesList: FC<OtherGamesListProps> = ({
  title,
  LinkComponent,
  games,
}) => {
  return (
    <List subheader={<ListSubheader>{title}</ListSubheader>}>
      {games.map(({ name, icon, url, description }) => {
        const Component: FC = ({ children }) => (
          <>{LinkComponent(url, children)}</>
        )
        return (
          <Component>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={name} src={icon} />
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {description}
                  </Typography>
                }
              />
            </ListItem>
          </Component>
        )
      })}
    </List>
  )
}
