import React, { FC } from "react"
import Layout from "../Layout"
import Seo from "../Seo"
import { BaseGameLocales, BaseGame } from "@monorepo/games/BaseGame"
import { Button, Typography } from "@material-ui/core"

export interface BaseGameEndLayoutProps {
  game: BaseGame
  language: BaseGameLocales
  onNew: () => void
}
export const BaseGameEndLayout: FC<BaseGameEndLayoutProps> = ({
  game,
  language,
  onNew,
}) => {
  return (
    <Layout
      primaryColor={game.colors.primary}
      primaryColorDark={game.colors.primaryDark}
      title={game.name[language]}
    >
      <Seo
        name={game.name[language]}
        description={game.description[language]}
        language="en"
      />

      <Typography variant="h3" component="h1" gutterBottom>
        Game over
      </Typography>
      <Button variant="contained" color="primary" size="large" onClick={onNew}>
        {game.newGame[language]}
      </Button>
    </Layout>
  )
}
