export const cardImages = {
  "2_hearts": require("../images/2_hearts.png"),
  "3_hearts": require("../images/3_hearts.png"),
  "4_hearts": require("../images/4_hearts.png"),
  "5_hearts": require("../images/5_hearts.png"),
  "6_hearts": require("../images/6_hearts.png"),
  "7_hearts": require("../images/7_hearts.png"),
  "8_hearts": require("../images/8_hearts.png"),
  "9_hearts": require("../images/9_hearts.png"),
  "10_hearts": require("../images/10_hearts.png"),
  J_hearts: require("../images/J_hearts.png"),
  Q_hearts: require("../images/Q_hearts.png"),
  K_hearts: require("../images/K_hearts.png"),
  A_hearts: require("../images/A_hearts.png"),
  "2_clubs": require("../images/2_clubs.png"),
  "3_clubs": require("../images/3_clubs.png"),
  "4_clubs": require("../images/4_clubs.png"),
  "5_clubs": require("../images/5_clubs.png"),
  "6_clubs": require("../images/6_clubs.png"),
  "7_clubs": require("../images/7_clubs.png"),
  "8_clubs": require("../images/8_clubs.png"),
  "9_clubs": require("../images/9_clubs.png"),
  "10_clubs": require("../images/10_clubs.png"),
  J_clubs: require("../images/J_clubs.png"),
  Q_clubs: require("../images/Q_clubs.png"),
  K_clubs: require("../images/K_clubs.png"),
  A_clubs: require("../images/A_clubs.png"),
  "2_diamonds": require("../images/2_diamonds.png"),
  "3_diamonds": require("../images/3_diamonds.png"),
  "4_diamonds": require("../images/4_diamonds.png"),
  "5_diamonds": require("../images/5_diamonds.png"),
  "6_diamonds": require("../images/6_diamonds.png"),
  "7_diamonds": require("../images/7_diamonds.png"),
  "8_diamonds": require("../images/8_diamonds.png"),
  "9_diamonds": require("../images/9_diamonds.png"),
  "10_diamonds": require("../images/10_diamonds.png"),
  J_diamonds: require("../images/J_diamonds.png"),
  Q_diamonds: require("../images/Q_diamonds.png"),
  K_diamonds: require("../images/K_diamonds.png"),
  A_diamonds: require("../images/A_diamonds.png"),
  "2_spades": require("../images/2_spades.png"),
  "3_spades": require("../images/3_spades.png"),
  "4_spades": require("../images/4_spades.png"),
  "5_spades": require("../images/5_spades.png"),
  "6_spades": require("../images/6_spades.png"),
  "7_spades": require("../images/7_spades.png"),
  "8_spades": require("../images/8_spades.png"),
  "9_spades": require("../images/9_spades.png"),
  "10_spades": require("../images/10_spades.png"),
  J_spades: require("../images/J_spades.png"),
  Q_spades: require("../images/Q_spades.png"),
  K_spades: require("../images/K_spades.png"),
  A_spades: require("../images/A_spades.png"),
}
