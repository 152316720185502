import React, { FC } from "react"
import Layout from "../Layout"
import Seo from "../Seo"
import { BaseGameLocales, BaseGame } from "@monorepo/games/BaseGame"
import { Button, Typography, Box } from "@material-ui/core"
import emojiFlags, { CountryData } from "emoji-flags"

interface SupportedLanguage {
  flag: keyof typeof emojiFlags
  url: string
}

export interface BaseGameStartLayoutProps {
  game: BaseGame
  language: BaseGameLocales
  onStart: () => void
  supportedLanguages?: SupportedLanguage[]
  onLanguageChange?: (url: string) => void
}
export const BaseGameStartLayout: FC<BaseGameStartLayoutProps> = ({
  game,
  language,
  onStart,
  supportedLanguages,
  onLanguageChange,
  children,
}) => {
  return (
    <Layout
      primaryColor={game.colors.primary}
      primaryColorDark={game.colors.primaryDark}
      title={game.name[language]}
    >
      <Seo
        name={game.name[language]}
        description={game.description[language]}
        language="en"
      />

      {supportedLanguages && (
        <>
          {supportedLanguages.map(({ flag, url }) => (
            <Button
              size="small"
              variant="outlined"
              onClick={() => onLanguageChange && onLanguageChange(url)}
              key={flag}
            >
              {(emojiFlags[flag] as CountryData).emoji}
            </Button>
          ))}
        </>
      )}

      <Typography variant="h3" component="h1" gutterBottom>
        {game.name[language]}
      </Typography>
      <Typography gutterBottom={true}>{game.tutorial[language]}</Typography>

      <Box m={2}>{children}</Box>

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onStart}
      >
        {game.startGame[language]}
      </Button>
    </Layout>
  )
}
