import { CardGame } from "./CardGame"

export const hitler: CardGame = {
  colors: {
    primary: "#388E3C",
    primaryDark: "#1A5E20",
  },
  name: {
    fi: "Hitler juomapeli",
    en: "Hiter drinking game",
  },
  description: {
    fi:
      "Pelaa Hitler juomapeliä ilman kortteja netissä. Säännöt sisältyvät peliin. Pelaa nyt tätä hauskaa juomapeliä netissä",
    en:
      "Pelaa Hitler juomapeliä ilman kortteja netissä. Säännöt sisältyvät peliin. Pelaa nyt tätä hauskaa juomapeliä netissä",
  },
  tutorial: {
    fi:
      'Hitler juomapelin ideana on nostaa kortteja vuorollaan. Sovelluksessa. nostat kortin painamalla "Seuraava kortti" painikeeta tai kortin kuvaa. Kortin alapuolella lukee kortin nimi ja tehtävä mitä pitää tehdä. Hauskoja juomapelihetkiä ja muistakaa juoda vastuudella.',
    en:
      'Hitler juomapelin ideana on nostaa kortteja vuorollaan. Sovelluksessa. nostat kortin painamalla "Seuraava kortti" painikeeta tai kortin kuvaa. Kortin alapuolella lukee kortin nimi ja tehtävä mitä pitää tehdä. Hauskoja juomapelihetkiä ja muistakaa juoda vastuudella.',
  },
  startGame: {
    fi: "Aloita peli",
    en: "Start game",
  },
  newGame: {
    fi: "Uusi peli",
    en: "New game",
  },
  drawNext: {
    fi: "Seuraava kortti",
    en: "Next card",
  },
  cards: {
    A: {
      title: { fi: "Antaa", en: "Antaa" },
      question: {
        fi: "Saat päätää pelaajan, joka juo 3 huikkaa.",
        en: "Saat päätää pelaajan, joka juo 3 huikkaa.",
      },
    },
    2: {
      title: { fi: "Ottaa", en: "Ottaa" },
      question: {
        fi: "Joudut juomaan 3 huikkaa.",
        en: "Joudut juomaan 3 huikkaa.",
      },
    },
    3: {
      title: { fi: "123", en: "123" },
      question: {
        fi:
          "Kortin saanut juo yhden huikan, vieressä oleva juo 2 huikkaa ja hänen vieressä oleva juo 3 huikkaa ja niin edespäin kunnes viimeinen juo osallistujien määrän verran huikkia.",
        en:
          "Kortin saanut juo yhden huikan, vieressä oleva juo 2 huikkaa ja hänen vieressä oleva juo 3 huikkaa ja niin edespäin kunnes viimeinen juo osallistujien määrän verran huikkia.",
      },
    },
    4: {
      title: { fi: "Hitler 1", en: "Hitler 1" },
      question: {
        fi:
          "Jos kortti numero 4 lyödään pöytään, niin jokaisen pitää huutaa Hitler. Viimeiseksi huutanut juo 3 huikkaa.",
        en:
          "Jos kortti numero 4 lyödään pöytään, niin jokaisen pitää huutaa Hitler. Viimeiseksi huutanut juo 3 huikkaa.",
      },
    },
    5: {
      title: { fi: "Hitler 2", en: "Hitler 2" },
      question: {
        fi:
          "Jos kortti numero 5 lyödään pöytään, niin jokaisen pitää huutaa Hitler. Viimeiseksi huutanut juo 3 huikkaa.",
        en:
          "Jos kortti numero 5 lyödään pöytään, niin jokaisen pitää huutaa Hitler. Viimeiseksi huutanut juo 3 huikkaa.",
      },
    },
    6: {
      title: { fi: "Kategoria", en: "Kategoria" },
      question: {
        fi:
          "Kortin saanut saa päättää kategorian. Eli jos kategoria on esim. laskettelu merkit niin, kortin saanut sanoo Volcom, viereinen pelaaja sanoo Burton ja niin edespäin. Pelaaja, joka ei saa sanottua jotain merkkiä 3 sekunnin sisällä tai sanoo jo sanotun merkin, joutuu juomaan 3 huikkaa.",
        en:
          "Kortin saanut saa päättää kategorian. Eli jos kategoria on esim. laskettelu merkit niin, kortin saanut sanoo Volcom, viereinen pelaaja sanoo Burton ja niin edespäin. Pelaaja, joka ei saa sanottua jotain merkkiä 3 sekunnin sisällä tai sanoo jo sanotun merkin, joutuu juomaan 3 huikkaa.",
      },
    },
    7: {
      title: { fi: "Vesiputous", en: "Vesiputous" },
      question: {
        fi:
          "Kortin saanut aloittaa juomisen. Vasta kun ensimmäinen lopettaa juomisen saa viereinen pelaaja lopettaa juomisen ja kun hän lopettaa vieressä oleva saa lopettaa ja niin edespäin. Jokainen pelaaja joutuu aloittamaan samaan aikaan kuin ensimmäinen. Juoman loputtua kesken kierroksen ei tarvitse juoda lisää kierroksen aikana. Jos lopetat juomisen kesken vesiputouksen ja sinusta aloittajaan on esim. 5 pelaajaa, niin sakkosi on 5 kertaa 3 eli 15 hörppyä.",
        en:
          "Kortin saanut aloittaa juomisen. Vasta kun ensimmäinen lopettaa juomisen saa viereinen pelaaja lopettaa juomisen ja kun hän lopettaa vieressä oleva saa lopettaa ja niin edespäin. Jokainen pelaaja joutuu aloittamaan samaan aikaan kuin ensimmäinen. Juoman loputtua kesken kierroksen ei tarvitse juoda lisää kierroksen aikana. Jos lopetat juomisen kesken vesiputouksen ja sinusta aloittajaan on esim. 5 pelaajaa, niin sakkosi on 5 kertaa 3 eli 15 hörppyä.",
      },
    },
    8: {
      title: { fi: "Riimi", en: "Riimi" },
      question: {
        fi:
          "Riimi kortin saanut pelaaja aloittaa sanomalla sana esim. tiili hänen vieressään oleva pelaaja sanoo jonkun tiili sanaan rimmaavan sanan kuten esim. viili ja seuraava sanoo siili ja niin edespäin. Jos pelaaja ei keksi mitään 3 sekuntiin tai sanoo jo ennen sanotun riimin hän juo 3 huikkaa.",
        en:
          "Riimi kortin saanut pelaaja aloittaa sanomalla sana esim. tiili hänen vieressään oleva pelaaja sanoo jonkun tiili sanaan rimmaavan sanan kuten esim. viili ja seuraava sanoo siili ja niin edespäin. Jos pelaaja ei keksi mitään 3 sekuntiin tai sanoo jo ennen sanotun riimin hän juo 3 huikkaa.",
      },
    },
    9: {
      title: { fi: "Sääntö", en: "Sääntö" },
      question: {
        fi:
          "Kortin saanut pelaaja päättää, mikä sääntö peliin tulee. Esim. Ei saa kiroilla pöydässä tai pitää juoda vain vasemmalla kädellä. Pelin aikana rikkeen tehnyt joutuu juomaan 3 huikkaa.",
        en:
          "Kortin saanut pelaaja päättää, mikä sääntö peliin tulee. Esim. Ei saa kiroilla pöydässä tai pitää juoda vain vasemmalla kädellä. Pelin aikana rikkeen tehnyt joutuu juomaan 3 huikkaa.",
      },
    },
    10: {
      title: { fi: "Kysymysmestari", en: "Kysymysmestari" },
      question: {
        fi:
          "Kortin saanut pelaaja on kysymysmestari. Kysymysmestari saa pelin aikana kysyä esim. mikä on jonkun pelaajan nimi, jos pelaaja vastaa hän joutuu juomaan 3 huikkaa. Kysymysmestari saa kysyä niin monesti kun haluaa kunnes, joku muu nostaa tämän kortin. Jos kysymys liittyy peliin tai sääntöihin ei kolmea huikkaa tarvitse ottaa.",
        en:
          "Kortin saanut pelaaja on kysymysmestari. Kysymysmestari saa pelin aikana kysyä esim. mikä on jonkun pelaajan nimi, jos pelaaja vastaa hän joutuu juomaan 3 huikkaa. Kysymysmestari saa kysyä niin monesti kun haluaa kunnes, joku muu nostaa tämän kortin. Jos kysymys liittyy peliin tai sääntöihin ei kolmea huikkaa tarvitse ottaa.",
      },
    },
    J: {
      title: { fi: "Taukokortti", en: "Taukokortti" },
      question: {
        fi:
          "Taukokortin saanut pelaaja saa pitää tauon milloin tahansa pelin aikana. Tauko koskee pelin kaikkia pelaajia, jonka pituuden määrittää kortin saaja. Tauon jälkeen taukokortin saaja menettää taukokorttinsa.",
        en:
          "Taukokortin saanut pelaaja saa pitää tauon milloin tahansa pelin aikana. Tauko koskee pelin kaikkia pelaajia, jonka pituuden määrittää kortin saaja. Tauon jälkeen taukokortin saaja menettää taukokorttinsa.",
      },
    },
    Q: {
      title: { fi: "Huora", en: "Huora" },
      question: {
        fi:
          "Huora kortin saanut pelaaja voi määrittää itselleen pelaajan, joka juo aina silloin kun hän juo. Kortti on voimassa kunnes, joku muu nostaa tämän kortin.",
        en:
          "Huora kortin saanut pelaaja voi määrittää itselleen pelaajan, joka juo aina silloin kun hän juo. Kortti on voimassa kunnes, joku muu nostaa tämän kortin.",
      },
    },
    K: {
      title: { fi: "Tarina", en: "Tarina" },
      question: {
        fi:
          "Kortin saanut pelaaja aloittaa tarinan sanomalla yhden sanan, esim. jätkä. Viereinen pelaaja jatkaa tarinaa sanomalla jätkä meni. Hänestä taas viereinen pelaaja jatkaa tarinaa sanomalla jätkä meni baariin ja niin edespäin. Tarina jatkuu niin kauan kunnes, joku pelaaja unohtaa tarinan sanat tai menee sekaisin niiden järjestyksessä. Sanat unohtanut pelaaja joutuu juomaan 3 huikkaa.",
        en:
          "Kortin saanut pelaaja aloittaa tarinan sanomalla yhden sanan, esim. jätkä. Viereinen pelaaja jatkaa tarinaa sanomalla jätkä meni. Hänestä taas viereinen pelaaja jatkaa tarinaa sanomalla jätkä meni baariin ja niin edespäin. Tarina jatkuu niin kauan kunnes, joku pelaaja unohtaa tarinan sanat tai menee sekaisin niiden järjestyksessä. Sanat unohtanut pelaaja joutuu juomaan 3 huikkaa.",
      },
    },
  },
}
