import * as React from "react"
import { ThemeProvider, makeStyles } from "@material-ui/styles"
import { Container, createMuiTheme } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles({
  root: {
    paddingTop: 20,
    padding: 5,
    // @ts-ignore
    textAlign: "center",
  },
})

const Layout: React.FunctionComponent<{
  primaryColor: string
  primaryColorDark: string
  title: string
}> = ({ children, primaryColor, primaryColorDark, title }) => {
  const classes = useStyles({})

  const theme = createMuiTheme({
    palette: {
      primary: {
        dark: primaryColorDark,
        main: primaryColor,
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container className={classes.root}>{children}</Container>
    </ThemeProvider>
  )
}

export default Layout
