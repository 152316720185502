import * as React from "react"
import Helmet from "react-helmet"

interface Props {
  name: string
  description: string
  language?: string
}
const Seo: React.FunctionComponent<Props> = ({
  name,
  description,
  language,
}) => {
  return (
    <Helmet>
      <html lang={language || "fi"} />
      <title>{`${name} | Juomapeli`}</title>
      <meta name="description" content={description} />

      <style>{`
        body {
          margin: 0;
        }
      `}</style>
    </Helmet>
  )
}

export default Seo
